export const texts: string[] = [
  "React",
  ".NET",
  "TypeScript",
  "C#",
  "Java",
  "NodeJS",
  "Python",
  "JWT",
  "Next.js",
  "Git",
  "Axios",
  "Moment.js",
  "AWS Bedrock",
  "Azure",
  "Azure AD",
  "Recharts",
  "Redux",
  "REST",
  "D3.js",
  "DC.js",
  "PostgreSQL",
  "TDD",
  "SQL Server",
  "NoSQL",
];
