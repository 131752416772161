import useTheme from "../../../hooks/useTheme";
export default function EyeGlasses() {
  const { mode } = useTheme();
  const color = mode === "dark" ? "#ffffff" : "#000000";
  return (
    <svg fill={color} width='200px' height='200px' viewBox='0 0 31.992 31.992'>
      <g>
        <g>
          <path
            d='M31.992,14.017c0-1.412,0-3.604-1.441-3.604c-0.688,0-3.113-0.868-5.9-0.268c-3.055,0.657-6.543,1.349-8.654,1.349
			c-2.112,0-5.6-0.69-8.653-1.35c-2.787-0.601-5.213,0.268-5.902,0.268C0,10.411,0,12.604,0,14.016c0,0.122,0.268,0.232,0.714,0.333
			c-0.08,0.375-0.101,0.766-0.037,1.16l0.538,3.398c0.277,1.738,1.934,3.152,3.693,3.152h4.767c1.764,0,3.395-1.422,3.63-3.148
			l0.663-3.399c0.079-0.586-0.01-1.137-0.239-1.608c0.752-0.151,1.51-0.271,2.269-0.271c0.76,0,1.518,0.118,2.271,0.271
			c-0.23,0.473-0.319,1.028-0.236,1.629l0.654,3.359c0.24,1.747,1.871,3.169,3.634,3.169h4.768c1.759,0,3.415-1.414,3.692-3.153
			l0.538-3.397c0.063-0.395,0.044-0.786-0.036-1.16C31.725,14.248,31.992,14.138,31.992,14.017z M13.256,15.393l-0.663,3.399
			c-0.192,1.404-1.502,2.545-2.919,2.545H4.907c-1.42,0-2.758-1.141-2.98-2.543l-0.538-3.398c-0.105-0.658,0.057-1.308,0.452-1.826
			c0.397-0.52,0.981-0.846,1.579-0.915l7.751,0.615c0.69-0.073,1.289,0.138,1.674,0.579C13.19,14.248,13.336,14.803,13.256,15.393z
			 M30.604,15.396l-0.539,3.397c-0.221,1.402-1.56,2.545-2.979,2.545h-4.768c-1.418,0-2.727-1.143-2.922-2.566l-0.655-3.359
			c-0.084-0.611,0.062-1.166,0.407-1.563c0.334-0.383,0.832-0.594,1.404-0.594c0.088,0,0.176,0.005,0.334,0.016l7.619-0.617
			c0.663,0.07,1.248,0.396,1.645,0.916C30.548,14.09,30.71,14.738,30.604,15.396z'
          />
        </g>
      </g>
    </svg>
  );
}
